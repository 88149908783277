.action-comp-container {
  padding: 2% 1% 0 1%;
  background-color: #FFFFFF;
}

.action-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-left: 13px; */
  /* align-items: flex-end; */
}

.add-btn {
  background-color: #810055;
  border-radius: 50rem;
  color: white;
  border: 1px solid #810055;
  padding: 2px 4px;
  /* margin-left: 1rem; */
  /* height: 40px; */
}

.add-btn:hover {
  background-color: #810055;
  border: 1px solid #810055;
  color: white;
}

.add-btn:disabled {
  background-color: #F9F2F6;
  border: 1px solid #810055;
}

.add-btn:active,
.add-btn:focus {
  background-color: #810055 !important;
  border: 1px solid #810055 !important;
}

.plus-icon {
  margin: 0px 5px;
}

.add-btn-label {
  margin-right: 6px;
  font-size: 14px;
  font-family: "G&B-BodyCopyHighlight";
}

.parent-add-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.records-search {
  padding: 0px 5px;
  /* font-weight: 500; */
  width: 100%;
  height: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #F1F1ED;
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  border-radius: 4px;
  margin-right: 3%;
}

.records-search-input {
  border: 0;
  outline: 0;
  margin-left: 3%;
  font-size: 12px;
  width: 100%;
  color: #333333;
}

.action-download {
  width: 2rem;
  height: 2rem;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #810055;
}

.parent-search-download {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.child-download {
  margin-right: 5%;
}

.margin-20 {
  margin: 20%;
}

@media screen and (max-width: 768px) {
  .action-main {
    flex-direction: column;
    justify-content: center;
  }

  .action-comp-container {
    padding: 0.5rem 1rem 5px;
  }

  /* .parent-search-download {
    float: left;
  } */

  /* .parent-add-btn {
    margin-left: 0;
  } */

  /* .records-search {
    margin-left: 1rem;
  } */

  /* .records-search {
    width: 239px;
  } */

  .icon-border {
    float: right;
    /* margin-right: 0.7rem; */
  }
}

.icon-border:hover {
  filter: brightness(0) saturate(100%) invert(7%) sepia(98%) saturate(5306%) hue-rotate(314deg) brightness(89%) contrast(103%);
}

.currency-icon-border {
  /* padding: 5px; */
  margin: 5px;
}

.currency-icon-border:hover {
  filter: brightness(0) saturate(100%) invert(7%) sepia(98%) saturate(5306%) hue-rotate(314deg) brightness(89%) contrast(103%);
}

.center-align {
  align-items: center;
}

.session-name {
  font-family: "G&B-Headline";
  font-size: 16px;
  color: #333333;
}

.input-label {
  margin-left: 14px !important;
  font-size: 12px !important;
  padding: 0px !important;
}

.inputHeight {
  height: 32px;
}

.modalPopUpIconActionComp {
  color: #707070;
  margin-top: -1.6rem;
  margin-right: 0.7rem;
  float: right;
  z-index: 1000;
  /* position: relative; */
}