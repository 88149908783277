@import "./styles/index.scss";

@font-face {
  font-family: "G&B-BodyCopy";
  src: url("./fonts/G&B-BodyCopy.woff2") format("woff2"),
    url("./fonts/G&B-BodyCopy.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "G&B-Headline";
  src: url("./fonts/G&B-Headline.woff2") format("woff2"),
    url("./fonts/G&B-Headline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'G&B-HeadlineItalic';
  src: url('./fonts/G&B-HeadlineItalic.woff2') format('woff2'),
    url("./fonts/G&B-HeadlineItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "G&B-BodyCopyHighlight";
  src: url("./fonts/G&B-BodyCopyHighlight.woff2") format("woff2"),
    url("./fonts/G&B-BodyCopyHighlight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "G&B-Light";
  src: url("./fonts/G&B-Light.woff2") format("woff2"),
    url("./fonts/G&B-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: white;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  background: #707070;
}

.ag-body-horizontal-scroll-viewport {
  overflow-x: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #810055;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-container {
  padding: 0%;
  background-color: #FFFFFF;
}

.main-blank-container {
  padding: 0%;
}

.grid-background-color {
  background-color: #FFFFFF;
}

.g-input {
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.g-input label {
  font-family: "G&B-BodyCopy";
  box-sizing: border-box;
  background: white;
  padding: 3px;
  /* font-weight: bold; */
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: #707070;
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 1;
  cursor: text;
}

.g-input input,
.g-input textarea {
  border: 1px solid #F1F1ED;
  display: block;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  color: #333333;
}

.g-input:has(svg) input {
  padding-right: 2rem;
}

.g-input.fill {
  display: block;
  width: 100%;
}

.g-input input:focus,
.g-input textarea:focus {
  outline: 0;
  /* border-color: #1873e8; */
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  color: #333333;
  border-color: #333333;
  box-shadow: 0 0 0 0;
}

.g-input input:read-only,
.g-input input:disabled,
.g-input textarea:read-only,
.g-input textarea:disabled {
  color: #707070;
}

.g-select select:disabled {
  color: #707070;
  --bs-form-select-bg-img: none;
}

.g-input input:not(:empty)+label,
.g-input input:focus+label,
.g-input input:not(:placeholder-shown)+label,
.g-input textarea:focus+label,
.g-input textarea:not(:placeholder-shown)+label {
  transform: translateX(-4px) translateY(-15px);
  font-size: 11px;
  color: #707070;
  font-family: "G&B-BodyCopy";
}

.g-input input:focus+label,
.g-input textarea:focus+label {
  color: #707070 !important;
}

.docReview input[type=date]::-webkit-calendar-picker-indicator {
  visibility: visible;
}

.modalTitle {
  font-family: "G&B-Headline";
  font-size: 16px;
  color: #333333;
}

.sectionTitle {
  font-family: "G&B-Headline";
  color: #333333
}

.rightAlignedButton {
  float: right !important;
}

.leftAlignedRowData {
  text-align: left;
  width: auto;
  display: flex;
}

/* .btn-primary {
  background-color: #810055;
}

.btn-primary:hover {
  background-color: #117015;
} */

.btn-success {
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #810055;
  --bs-btn-active-border-color: #810055;
  --bs-btn-bg: #810055;
  --bs-btn-border-color: #810055 !important;
  --bs-btn-hover-border-color: #810055 !important;
  --bs-btn-hover-color: #F9F2F6;
  --bs-btn-hover-bg: #810055;
  -bs-btn-focus-shadow-rgb: none;
}

.btn-outline-erase {
  --bs-btn-color: #810055 !important;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #810055 !important;
  --bs-btn-hover-color: #810055 !important;
  --bs-btn-hover-bg: #fff !important;
  --bs-btn-hover-border-color: #810055 !important;
  --bs-btn-active-color: #810055 !important;
  --bs-btn-active-bg: #fff !important;
  --bs-btn-active-border-color: #810055 !important;
}

.btn-outline-success {
  border-radius: 20px;
  --bs-btn-color: #707070 !important;
  --bs-btn-border-color: #F1F1ED !important;
  --bs-btn-hover-bg: #ffffff !important;
  --bs-btn-hover-color: #707070 !important;
  --bs-btn-hover-border-color: #F1F1ED !important;
  --bs-btn-active-color: #707070 !important;
  --bs-btn-active-bg: #FFFFFF !important;
  --bs-btn-active-border-color: #F1F1ED !important;
  --bs-btn-border-width: 1.5px
    /* background-color: #F9F2F6; */
}

.btn-outline-primary {
  width: 95px;
  height: 30px;
  color: #810055;
  --bs-btn-color: none;
  --bs-btn-border-color: #810055;
  --bs-btn-hover-color: #810055;
  --bs-btn-hover-bg: none;
  --bs-btn-hover-border-color: #810055;
  --bs-btn-active-bg: none;
  --bs-btn-active-border-color: #810055;
  --bs-btn-active-color: #810055;
}

.resetBtn {
  text-decoration: none;
  color: #810055 !important;
  padding-left: 0px !important;
}

.noLeftPadding {
  padding-left: 0px !important;
}

.btn-position {
  text-align: right;
}

.btn-add-color {
  background-color: #810055;
  width: 100px;
  height: 35px;
  border-radius: 20px;
  padding: 4px 8px;
  /* --bs-btn-hover-border-color: #810055 !important; */
  /* --bs-btn-active-bg: #810055 !important; */
  --bs-btn-active-border-color: #810055 !important;
}

.btn-update-sequence {
  width: 152px;
  height: 32px;
}

.cancelBtn {
  border-radius: 20px;
  width: 100px;
  height: 35px;
}

.activeToggleLabel {
  padding: 10px;
  color: #33C037;
}

.inactiveToggleLabel {
  padding: 10px;
  color: #707070;
}

.form-switch .form-check-input:checked {
  background-color: #33C037;
  border-color: #33C037
}

.form-check-input {
  border-color: #707070;
}

.form-check-input:checked {
  background-color: #810055;
  border-color: #810055;
}

.row {
  padding-bottom: 5px;
}

.fieldLabel {
  font-size: 14px !important;
  padding-top: 10px !important;
  color: #333333 !important;
  font-family: "G&B-BodyCopy" !important
}

.locationType {
  padding-top: 0% !important;
  margin-bottom: 0% !important;
}

.semiBoldToggleButton {
  font-family: "G&B-BodyCopyHighlight" !important;
  font-size: 14px;
}

/* .resetBtn {
  --bs-btn-hover-border-color: :#ffffff !important;
} */

.flex-column {
  display: flex;
  flex-direction: column;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.updateChecklistBtn {
  background-color: #810055 !important;
  color: white !important;
  margin-right: 1% !important;
}

.cancelChecklistBtn {
  border: 1px solid !important;
  background-color: #fff !important;
  color: #810055 !important;
  border-color: #810055 !important;
}

.swal2-styled {
  font-family: "G&B-BodyCopyHighlight";
}

.swal2-styled.swal2-deny {
  border: 1px solid #810055;
  border-radius: 50rem;
  background-color: #fff;
  color: #810055;
  height: 40px;
  width: 109px;
  padding: 0px;
  font-size: 14px;
  margin-right: 4%;
}

.swal2-deny.swal2-styled:hover,
.swal2-deny.swal2-styled:focus {
  border-color: #810055 !important;
  color: #810055 !important;
  background-image: none !important;
  box-shadow: none !important;
}

.swal2-styled.swal2-deny:hover,
.swal2-styled.swal2-deny:active {
  border-color: #810055 !important;
  color: #810055 !important;
  background-color: #ffffff !important;
}

.swal2-styled.swal2-confirm:active {
  border-color: #810055 !important;
  color: #ffffff !important;
  background-color: #810055 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
  background-color: #810055;
  height: 40px;
  width: 109px;
  padding: 0px;
  font-size: 14px;
  border-radius: 50rem;
}

.swal-confirmation {
  width: 523px !important;
  border-radius: 0px;
}

.swal-success {
  width: 24em !important;
  font-size: 15px;
  font-family: "G&B-BodyCopy";
  color: #333333;
}

.swal2-title {
  text-align: left !important;
  margin-top: 5px !important;
  font-size: 16px;
  font-weight: 100 !important;
  font-family: "G&B-Headline";
  color: #333333;
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
}

.swal-confirmation .swal2-close {
  display: block !important;
  color: #707070;
  font-size: 30px;
}

.swal2-close:hover {
  color: #810055;
}

.swal-confirmation .swal2-actions {
  width: 100% !important;
  /* justify-content: space-around !important; */
  margin: 0px;
  padding-bottom: 30px !important;
}

.swal2-html-container {
  font-family: "G&B-BodyCopy" !important;
  font-size: 16px !important;
}

.swal-confirmation .swal2-html-container {
  margin: 50px !important;
}

.g-select select:focus+label,
.g-select select:not(:placeholder-shown)+label,
.g-select textarea:focus+label,
.g-select textarea:not(:placeholder-shown)+label {
  transform: translateX(-4px) translateY(-15px);
  font-size: 10px;
  color: #707070;
  font-family: "G&B-BodyCopy";
}

/* ///for setect */
.g-select {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.g-select label {
  font-family: "G&B-BodyCopy";
  box-sizing: border-box;
  background: white;
  padding: 3px;
  /* font-weight: bold; */
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: #707070;
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 1;
  cursor: text;
}

.g-select select {
  border: 1px solid #F1F1ED;
  display: block;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  color: #333333;
}

.g-select.fill {
  display: block;
  width: 100%;
}

.g-select select:focus {
  outline: 0;
  border-color: #333333;
  font-family: "G&B-BodyCopy";
  font-size: 14px;
}

.form-select:disabled {
  background-color: white;
}

.inputPasswordEyeIcon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 5px;
}


/* //for drop=zone */
.dropZoneContainer {
  position: relative;
  align-items: center;
  /* background: #E6EDFD; */
  border: 2px dashed #F1F1ED;
  border-radius: 5px;
  display: flex;
  /* border-color: #810055; */
  justify-content: center;
}

.dropZoneMainDiv {
  max-width: 40%;
}

.istdropZoneMainDiv .dropZoneMainDiv {
  max-width: 100%;
}

.dropzone-fileupload {
  /* padding-bottom: 15px; */
  text-align: center;
  align-items: center;
  width: 100%
}

.dropTextContainer {
  width: 100%;
  font-size: 14px;
  margin: 10px 0;
  text-align: center;
  align-content: center;
  align-items: center;
}

.dropTextContainer1 {
  width: 100%;
  font-size: 14px;
  /* margin: 10px 0; */
  /* text-align: center; */
  align-content: center;
  align-items: center;
}

.istdropTextContainer .dropTextContainer {
  margin: 0 0;
}

.dropSelectText {
  color: #810055;
}

/* .dropAlign{
  text-align: center;
  align-items: center;
} */


.dropSelectfiles {
  color: #707070;
  text-align: center;
  font-size: 12px;
}

.fileupload-name {
  color: #810055 !important;
  font-family: "G&B-BodyCopy", sans-serif !important;
}

/* 
 @media (max-width: 768px) {
  #Sidebar{
    display: none;
  }
} */
@media (max-width: 768px) {
  .modal-body {
    padding: 0.6rem;
  }

  .login-container .logoHeader {
    padding: 3rem;
  }

  .logoHeader {
    padding: 0.5rem 1.5rem 1rem 1.5rem;
    border-bottom: 1px solid #F1F1ED;
  }

  .head-logo,
  .system-name {
    display: none;
  }

  .LoginRightImageGodrej {
    height: 34px;
    width: 70px;
  }

  .LoginRightImageAem {
    height: 70px;
    width: 189px;
  }

  .dropZoneMainDiv {
    max-width: 100%;
  }

  .modal-title {
    display: flex;
    flex-direction: column;
  }

  .IstRefLabelResp {
    display: block;
  }

  .IstPowerRadio {
    padding-bottom: 1rem;
  }
}

.blurrOverlay {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.sidebar-head-2 {

  padding: 1.4rem;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  box-shadow: -3px 1px 3px 3px #f2f2f2;
}

@media (max-width: 767px) {
  .user-info {
    align-items: flex-start;
    color: #333333;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    margin: 0 0.5rem;
    padding: 0.25rem;
  }
}

.user-email {
  font-size: 10px;
  color: #333333;
}

.user-logo {
  align-items: center;
  background-color: #F9F2F6;
  border-radius: 50%;
  color: #810055;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
}

/* .user-logo.big {
  height: 3.5rem;
  width: 3.5rem;
  font-size: 24px;
  font-weight: 500;
} */

/* @media (max-width: 768px) {
  .side-bar {
    width: 18rem;
  }
} */

.optional {
  font-size: 14px !important;
  font-family: "G&B-HeadlineItalic" !important;
  color: #707070;
}

.req-input {
  color: #E1142E;
  font-family: "G&B-BodyCopy", sans-serif;
  font-size: 14px;
  /* font-weight: normal; */
}

/* CHECKLIST CSS START*/
/* .tab-content{
  margin-top: 1%;
} */
.checklist-header-column {
  width: 278px;
  height: 65px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D8D8D8;
  margin: 0 1% 1%;
  font-size: 14px;
  padding: 0.5rem;
}

.checklist-header-column .form-check-label {
  color: #333333;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.add-new-row {
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  display: flex;
  justify-content: start;
}

.add-new-link {
  text-decoration: none;
  color: #810055;
}

.subtext {
  font-size: 12px;
  color: #707070;
}

/* CHECKLIST CSS END*/


.projChecklist .accordion-button {
  font-family: 'G&B-BodyCopy', sans-serif;
  font-size: 14px;
}

.projChecklist .accordion-button:not(.collapsed) {
  /* background-color: #E6EDFE; */
  color: #333333;
  font-family: 'G&B-BodyCopy', sans-serif;
  font-size: 14px;
}

.projChecklist .accordion-button::after,
.projectDoc .accordion-button::after {
  background: url("../src/icons/accordianIcon.svg");
}

.projChecklist .accordion-button:not(.collapsed)::after,
.projectDoc .accordion-button:not(.collapsed)::after {
  background: url("../src/icons/accordianIcon.svg");
  transform: rotate(0.5turn);
}

.fireIntRadio input {
  margin-top: 15px !important;
}

.filterSubmit {
  border-radius: 20px;
  height: 32px;
  width: 104px;
  padding: 0px;
}

.separatorLine {
  border-top: 2px solid;
}

.istseparatorLine {
  border-top: 1px solid;
}

.docRevInputMargin {
  margin-bottom: 15px !important;
}

@media screen and (max-width: 768px) {
  .home-sidebar {
    display: none;
  }

  .semiBoldToggleButton {
    font-family: "G&B-BodyCopyHighlight" !important;
    font-size: 12px;
  }

  .btn-add-color {
    background-color: #810055;
    width: 72px;
    height: 32px;
  }

  .cancelBtn {
    width: 72px;
    height: 32px;
    border-radius: 20px;
  }

  .resetBtn {
    padding: 0%;
  }

  .row {
    padding-right: 0px;
    padding-left: 0px;
  }

  .checklist-header-column {
    width: 90%;
    margin: 0 3% 3%;
  }

  .fireIntRadio input {
    margin-top: 3px !important;
  }

  .filterSubmit {
    border-radius: 20px;
    height: 32px;
    width: 104px;
    /* float: right !important; */
  }

  .docRevTopMargin {
    margin-top: 15px;
  }

  .rightAlignedButtonInResp {
    float: right !important;
  }

  .gdpr-submit-btn-col {
    width: 50% !important;
    float: left !important;
  }

  .gdpr-erase-btn-col {
    float: right !important;
  }
}


.user-name-initials {
  display: inline-flex;
  /* justify-content: flex-end; */
  align-items: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  /* margin: 0 0.5rem; */
  justify-content: center;
  align-items: flex-start;
  /* color: #5f5f5f; */
  padding: 0.25rem;
  font-size: 14px;
}

.user-name {
  /* font-size: 12px; */
  font-family: "G&B-BodyCopy";
  line-height: 1rem;
  color: #333333;
}


.user-initials {
  height: 2rem;
  width: 2rem;
  background-color: #F9F2F6;
  color: #810055;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-initials-color {
  background-color: #F9F2F6;
  color: #810055;
}

.telephone-no {
  font-size: 10px;
  color: #707070;
}

.greenButton {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  float: right;
  font-family: "G&B-BodyCopyHighlight";
  font-size: 14px;
  background-color: #810055;
}

.radio-item input[type=radio]:checked {
  accent-color: #810055 !important;
}

.radio-item input[type=radio]:checked,
:hover {
  accent-color: #810055 !important;
}

.radio-item input[type='radio']:hover {
  accent-color: #810055 !important;
}

.ddinput {
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  padding-left: 1px;
  display: inline-block;
  color: #707070
}

.btn:disabled {
  border-color: rgb(129, 0, 133, 0.4) !important;
  background-color: rgb(129, 0, 133, 0.4) !important;
  color: #FFFFFF;
  opacity: 1 !important;
  --bs-btn-border-width: 0
}

/* .records-search1 {
  padding: 0px 10px;
  font-weight: 500;
  width: 239px;
  margin-right: 1.6rem;
  height: 32px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #ebebec;
  display: flex; */
/* justify-content: flex-start; */
/* align-items: center;
  border-radius: 4px;
} */

.italicFont {
  font-family: "G&B-HeadlineItalic";
  font-size: 12px;
  color: #707070;
}

.modal-content {
  border-radius: 0px;
}

.modal-title {
  font-size: 18px;
  /* font-weight: 600; */
  font-family: "G&B-Headline";
  color: #333333;
}

.modal-header .btn-close {
  width: 0px !important;
  height: 0px !important;
  margin-right: 5px !important;
}

.btn-close:hover {
  /* filter: brightness(0) saturate(100%) invert(7%) sepia(98%) saturate(5306%) hue-rotate(314deg) brightness(89%) contrast(103%); */
  box-shadow: none;
}

#errorPage {
  /* display: flex;
  justify-content: center; */
  /* width: calc(100% - 360px); */
  color: #E1142E;
  height: 100%;
  text-align: center;
  transform: translateY(5%);
}

.frm-label {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerTabKey {
  --bs-nav-link-padding-x: none;
  --bs-nav-link-padding-y: none;
}

.modal-backdrop {
  z-index: 1055 !important;
}

.centerAlign {
  justify-content: center;
}

.textCenterAlign {
  text-align: center;
}

.align-right {
  text-align: right;
}

.verticalLine {
  border-left: 1px solid #707070;
  height: 18px;
}

.prevNext {
  --bs-btn-padding-y: none;
}

.prevArrow {
  margin-right: 15px;
}

.nextArrow {
  margin-left: 15px;
}

.disabledArrow {
  color: #707070 !important;
}

.activeArrow {
  color: #810055 !important;
}

/* Spinner*/

.spinner {
  position: absolute;
  width: 5rem !important;
  height: 5rem !important;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  z-index: 5000 !important;
}

.spinner-box {
  display: grid;
  position: fixed;
  z-index: 5060 !important;
  inset: 0;
  background: rgba(59, 63, 74, 0.85);
}

/* Spinner end*/
.height-20 {
  height: 20vh !important;
}


/* Project Documents Start here */

.documents-header {
  font-size: 14px;
  font-family: "G&B-Headline" !important;
  align-items: center;
}

.document-label {
  color: #810055;
  text-decoration: underline;
  /* background-color: #E9FAE9; */
  border-radius: 5px;
  /* width: 150px */
  cursor: pointer
}

.document-label-uploaded {
  color: #810055;
  text-decoration: underline;
  justify-content: center;
  cursor: pointer;
  width: 120px;
}

.document-date-time {
  display: flex;
  /* justify-content: center;
  align-content: center;
  padding: 0px; */
  font-size: 10px;
  color: #707070;
}

.document-details {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 0px;
  font-size: 12px;
}

.documents-upload-col {
  width: auto;
}

.userdetails-header {
  display: flex;
  height: 2.5rem;
  align-items: center;
  font-size: 12px;
  font-weight: bold
}

.ellipsised-auther-header {
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px
}

.document-file-name {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
  width: 100%;
}

.documents-generate-btn {
  align-items: center;
  display: grid;
  /* justify-content: center; */
  padding: 5px
}

.initials-auther-inline {
  display: contents;
}

.ellipsised-label {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsised-label-auther {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 105px; */
  width: 90%;
  color: #333333;
}

.documents-dropZoneContainer1 {
  position: relative;
  border: 1px dashed #810055;
  border-radius: 5px;
  display: flex;
  /* height: 2.8rem; */
  height: 37px;
  width: 81px;
  align-items: center;
}

.DocUploaddropText {
  display: inline-block;
  /* width: 105px; */
  font-size: 12px;
  line-height: 1.2;
}

.accordion-button:not(.collapsed) {
  background-color: #F1F1ED;
  color: #333333;
}

.projectDoc .accordion-button:not(.collapsed) .btn-outline-success {
  border-color: #707070;
  --bs-btn-hover-bg: #F1F1ED !important;
  --bs-btn-hover-border-color: #707070 !important;
  --bs-btn-active-bg: #F1F1ED !important;
}

.accordion-button:focus {
  border-color: #E8E8E8;
  box-shadow: unset;
}

.generate-all-btn {
  display: flex;
  justify-content: end;
}

.documents-document-details {
  padding: 5px;
  width: 160px;
}

.documents-document-generate {
  padding: 5px;
  width: 160px;
}

.service-equip-labeltext {
  color: #707070;
}


.noSelection {
  text-align: center;
  padding: 13% 4%;
  font-family: "G&B-BodyCopy", sans-serif;
  font-size: 16px;
  opacity: 30%;
}

.project-doc-accordian-item {
  margin-bottom: 10px;
  border: 1px solid #E8E8E8;
  border-radius: 5px 5px 0px 0px;
}

.project-doc-accordian .accordion-item:not(:first-of-type) {
  border-top: 1px solid #E8E8E8;
}

.project-doc-accordian-header {
  display: flex;
  flex-direction: row;
  font-family: "G&B-Headline", sans-serif;
  font-size: 14px;
}

.project-doc-buttons {
  display: flex;
  flex-direction: row;
}

.right-margin-button {
  margin-right: 5%;
}

.project-doc-upload,
.project-doc-generated {
  width: 11.4%;
}

.projDocParam {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  width: 100%;
}

.projDocParam .leftPanel,
.projDocParam .rightPanel {
  /* width: 50%; */
  /* margin: 10px !important; */
  background-color: #ffffff;
}

.projDocParam .leftPanel {
  position: relative;
  margin: 10px 0px 10px 10px;
  width: 50%;
}

.projDocParam .rightPanel {
  /* width: 100%; */
  margin: 10px 10px 10px 0px;
  width: 50%;
}

.action-icon {
  height: 30px;
  width: 30px;
}

.action-icon:hover {
  filter: brightness(0) saturate(100%) invert(7%) sepia(98%) saturate(5306%) hue-rotate(314deg) brightness(89%) contrast(103%);
}

.resizer {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0;
  width: 11px;
  height: 100%;
  cursor: col-resize;
  background-color: #F1F1ED;
}

.resizer::before {
  content: "";
  position: absolute;
  width: 24.5px;
  height: 100%;
  right: -7px;
  background-image: url("../src/icons/resizer.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.projDocLeftPanel {
  padding-top: 10px !important;
  padding-bottom: 30px !important;
  color: #333333 !important;
  font-family: "G&B-Headline";
  font-size: 14px;
}

.projDocRightPanel {
  padding-top: 10px !important;
  color: #333333 !important;
  font-family: "G&B-Headline";
  font-size: 14px;
}

.projDocRightPanelEquipName {
  color: #333333 !important;
  padding-top: 10px !important;
  font-family: "G&B-Headline";
  font-size: 14px;
}

.projDocAggridCell {
  padding-left: 10px;
  padding-right: 10px;
}

.projdoc-comp-container,
.ist-comp-container {
  background-color: #fff;
}

.ist-comp-container .grid-comp-container {
  padding: 1% 0% 2% 0% !important;
}

.ist-comp-container .grid-comp-container {
  padding: 1% 0% 2% 0% !important;
}

.ist-comp-container .grid-comp-container {
  padding: 1% 0% 2% 0% !important;
}

.right-padding-resize {
  padding-right: 23px;
}


.gdpr-gridcell {
  color: #333333 !important;
}

.criteria-select {
  padding: 7px !important;
}

.gdpr-submit-btn-col {
  width: auto;
  float: left;
}

.downloadISTsample1 {
  display: grid;
  justify-content: end;
}

.istSaveTitlebtn {
  color: #810055;
  font-family: "G&B-BodyCopy", sans-serif;
  font-size: 14px;
  text-decoration: none;
}

.disableddiv {
  pointer-events: none;
  opacity: 0.4;
}

.istDownloadSample {
  display: flex;
}

.downloadIstSample {
  font-family: "G&B-BodyCopy", sans-serif;
  font-size: 14px;
  color: #810055;
  margin-left: 1rem;
}

.downloadIstLink {
  color: #810055;
  text-decoration: none;
  width: 65%;
}

.isttooltip .tooltip-inner {
  background-color: #F1F1ED;
  color: #707070;
  font-family: "G&B-BodyCopy", sans-serif;
  font-size: 14px;
  min-width: 30px;
  opacity: 1;
  border-radius: 2%;
  /* border: #0636A5 1px solid; */
}

.istScriptTitle {
  background-color: #F9F2F6;
  /* border: #0636A5 solid 1px; */
  font-size: 14px;
  max-width: max-content;
  border-radius: 16px;
  display: flex;
  place-items: center;
  justify-content: space-around;
  padding: 0px 10px;
}

.IstScriptInfo {
  margin-right: 1rem;
  margin-top: -1.8rem;
  float: right;
}

.IstLabelWeb {
  display: none;
}

.filterList {
  display: flex;
  padding-bottom: 15px;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .project-doc-upload {
    width: 100%;
    padding: 5px;
  }

  .project-doc-generated {
    width: 66%;
    padding: 5px;
  }

  .documents-dropZoneContainer1 {
    width: 100%;
    align-items: center;
    margin-bottom: 5px;
  }

  .documents-document-details {
    width: 100% !important;
  }

  .document-label-uploaded {
    width: 100% !important;
  }

  .projdoc-comp-container,
  .ist-comp-container {
    background-color: #FFFFFF;
  }

  .right-padding-resize {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .projDocParam .leftPanel {
    width: 100% !important;
    margin: 0;
  }

  .projDocParam .rightPanel {
    width: 100% !important;
    margin: 0;
  }

  .card-left-panel {
    margin: 10px;
    padding: 0;
  }

}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .IstRefLabelResp {
    display: none;
  }
}

@media (max-width: 768px) {
  .Isttab3download {
    display: none;
  }

  .IstLabelWeb {
    display: block;
  }

  .downloadIstSample {
    margin-left: 0;
  }

  .dashboardPieResp {
    padding-bottom: 10px;
  }

  .dashboardPieRespDiv {
    display: grid;
    justify-content: center
  }
}

.activeToggleLabelProjDoc {
  padding-top: 8px;
  text-align: center;
  color: #33C037;
}

.inactiveToggleLabelProjDoc {
  padding-top: 8px;
  text-align: center;
  color: #707070;
}

.dashboardChartDiv {
  /* width: 397px; */
  /* padding-bottom: 10px; */
  background-color: white;
  height: 100% !important;
}

.cell-span {
  background-color: white;
}

.dashboard-projects {
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  padding: 5px;
  margin-bottom: 5px;
}

.dashboard-grid-container {
  padding: 10px;
  border: 1px;
}

.dashboardToggle {
  display: inline-block;
  text-align: center;
  margin: 10px;
  font-family: "G&B-BodyCopy" !important;
  font-size: 14px;
}

.dashboardToggle-select {
  color: #810055;
  font-size: 14px;
  font-weight: bold;
}

.active-dashboardToggleLabel {
  color: #810055;
  font-size: 14px;
  font-weight: bold;
}

.inactive-dashboardToggleLabel {
  color: #707070;
  font-size: 14px;
}

.grid-sor-columns {
  color: #ffffff !important;
  background-color: #707070;
  font-size: 14px;
  border-right: 0.5px solid #F1F1ED !important;
  font-weight: normal !important;
  font-family: "G&B-BodyCopy" !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.grid-dashboard-cells {
  border-right: 0.5px solid #F1F1ED !important;
  justify-content: center;
}

.dashboardChartRow {
  padding-top: 10px;
  /* background-color: #fff; */
  /* padding-left: 10px;
  padding-right: 12px; */
}

.legend-item-value {
  display: inline-block;
  max-width: 130px;
  text-align: left;
}

.apexcharts-legend {
  /* border-top: 1px solid #EBEBEC; */
  /* padding-left: 0px;
  padding-right: 0px; */
  /* justify-content: center !important; */
}

.chartHeaderLabel {
  padding-top: 8px;
  padding-left: 8px;
  background-color: white;
  padding-bottom: 8px;
}

/* .grid-comp-containerDashboard {
  padding-top: 10px;
} */

.dashboardBarChartDiv {
  padding-bottom: 10px;
  background-color: white;
}

#chart .apexcharts-tooltip {
  color: #fff;
  transform: translateX(10px) translateY(10px);
  overflow: visible !important;
  white-space: normal !important;
  text-wrap: wrap;
}

#chart .apexcharts-tooltip span {
  padding: 5px 10px;
  display: inline-block;
}

.dashboardLimitSet {
  width: 10px;
  height: 10px;
  /* UI Properties */
  border-radius: 10px;
  display: inline-flex;
}

.dashboardLimitLabel {
  padding-left: 5px;
  margin-right: 1rem;
  font-size: 11px;
  font-weight: normal;
  font-family: "G&B-BodyCopy", sans-serif;
}

.dashboardLimitSet1 {
  background: #2E7D32 0% 0% no-repeat padding-box;
}

.dashboardLimitSet2 {
  background: #B98913 0% 0% no-repeat padding-box;
}

.dashboardLimitSet3 {
  background: #3949AB 0% 0% no-repeat padding-box;
}

.dashboardLimitSet4 {
  background: #E07D01 0% 0% no-repeat padding-box;
}

.dashboardLimitSet5 {
  background: #C62828 0% 0% no-repeat padding-box;
}

.show-cell {
  align-content: start !important;
  padding-top: 8px;
  padding-left: 8px !important;
  padding-right: 8px !important;
  background-color: white;
  border-left: 0.5px solid #F1F1ED !important;
  border-right: 0.5px solid#F1F1ED !important;
  border-bottom: 0.5px solid #F1F1ED !important;
}

.header-cell {
  border-right: 0.5px solid#F1F1ED !important;
  padding: 1px !important;
  color: #707070 !important;
  font-family: "G&B-Headline", sans-serif !important;
}

.show-name {
  font-weight: normal;
}

.show-data {
  z-index: 2 !important;
  /* padding-top: 0px !important; */
}

.cell-wrap-textDashboard {
  line-height: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ag-horizontal-right-spacer {
  min-height: 20px;
  position: relative;
  top: -10px;
}

.agGridContainerDashboard {
  height: 600px;
  width: 100%;
}


.agHeaderCellDashboard {
  padding-left: 6px !important;
  padding-right: 0px !important;
}

/* .ag-theme-material {
  --ag-row-hover-color: #FFFFFF !important;
  --ag-column-hover-color: #F9F2F6;
  --ag-selected-row-background-color: #F9F2F6 !important;
} */

/* .ag-theme-material.ag-row:hover {
  border: 1px solid red;
} */

.dashboard-search-export {
  justify-content: flex-end !important;
}

.dashboardTab1Limit {
  width: 54px;
  height: 26px;
  text-align: center;
  border-radius: 5px;
}

.dashboardLimitCheck1 {
  background-color: #E8F5E9;
  color: #2E7D32;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardLimitCheck2 {
  background-color: #FFF9C4;
  color: #B98913;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardLimitCheck3 {
  background-color: #E8EAF6;
  color: #3949AB;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardLimitCheck4 {
  background-color: #FFF3E0;
  color: #E07D01;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardLimitCheck5 {
  background-color: #FFEBEE;
  color: #C62828;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardTabData {
  background-color: #FFFFFF;
  padding: 10px !important;
}

.commonBorder {
  border: 2px solid #F1F1ED !important;
}

.wordBreak {
  word-break: break-word;
}

.dasboardNA {
  color: #3CB23D;
  background-color: rgba(51, 192, 55, .1);
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-border {
  border-bottom: 2px solid #F1F1ED !important;
}

.apexcharts-title-text {
  font-family: "G&B-Headline";
  color: #333333;
}

.apexcharts-legend-text,
.apexcharts-text tspan {
  font-family: "G&B-BodyCopy", sans-serif !important;
  color: #333333 !important;
}

.alert-light {
  --bs-alert-color: #333333;
  --bs-alert-bg: #F1F1ED;
  --bs-alert-border-color: #F1F1ED;
  --bs-alert-link-color: #333333;
}


.market-wrapper {
  /* background-color: #95a849; */
  margin: 10px 10px 10px 10px;
}