@font-face {
  font-family: "G&B-BodyCopy";
  src: url("./fonts/G&B-BodyCopy.woff2") format("woff2"),
    url("./fonts/G&B-BodyCopy.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "G&B-Headline";
  src: url("./fonts/G&B-Headline.woff2") format("woff2"),
    url("./fonts/G&B-Headline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'G&B-HeadlineItalic';
  src: url('./fonts/G&B-HeadlineItalic.woff2') format('woff2'),
    url("./fonts/G&B-HeadlineItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "G&B-BodyCopyHighlight";
  src: url("./fonts/G&B-BodyCopyHighlight.woff2") format("woff2"),
    url("./fonts/G&B-BodyCopyHighlight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "G&B-Light";
  src: url("./fonts/G&B-Light.woff2") format("woff2"),
    url("./fonts/G&B-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "G&B-BodyCopy" !important;
  font-size: 14px;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.sidebar-body {
  padding-top: 1.5rem;
  /* height: 80%; */
  height: 470px;
  overflow-y: auto;
  scrollbar-width: none;
}

.sidebar-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 0px;
  padding: 0px;
}

.sidebar-head {
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F1F1ED;
  padding: 1.73rem 0.75rem;
}

.side-bar.closed,
.side-bar.closed .sidebar-head,
.side-bar.closed .sidebar-body {
  width: 56px;
}

.side-bar.closed .sidebar-head .sidebar-logo,
.side-bar.closed .menu-name {
  display: none;
}

.side-bar.closed .menu {
  opacity: 0;
  margin: 0;
}

.menu {
  margin-left: 24px;
  padding-bottom: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  color: #810055;
}

.side-bar.open .sidebar-godrej-logo {
  width: 75px;
  /* margin-left: 30%;
  margin-top: 65%; */
  position: fixed;
  bottom: 10px;
}

.side-bar.closed .sidebar-godrej-logo {
  width: 55px;
  /* padding: 0.5rem 0.65rem;
  margin-top: 10.5rem; */
  position: fixed;
  bottom: 10px;
}

.sidebar-godrej-logo-div {
  display: flex;
  justify-content: center;
}

.side-bar.closed .nav-list-item {
  padding: 0.5rem 0.65rem;
}

.head-toggle:hover {
  filter: brightness(0) saturate(100%) invert(7%) sepia(98%) saturate(5306%) hue-rotate(314deg) brightness(89%) contrast(103%);
}

.head-toggle {
  display: block;
  cursor: pointer;
}

.head-logo {
  font-size: 26px;
  font-family: "G&B-Headline";
  line-height: 20px;
  /* letter-spacing: 0px; */
  /* font-weight: bold; */
  color: #810055;
}

.system-name {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0px;
  font-family: "G&B-BodyCopy";
  color: #707070;
}

.side-bar {
  width: 18rem;
}

.nav-list {
  list-style-type: none;
  /* margin-top: 0.25rem; */
  padding-left: 0;
}


.nav-list-item {
  padding: 0.5rem 1rem;
  height: 3rem;
  display: flex;
  align-items: center;
  color: #707070;
  text-decoration: none;
}

.nav-list-item.active,
.nav-list-item:hover,
.nav-list-item.active .menu-icon,
.nav-list-item:hover .menu-icon {
  background-color: #F9F2F6 !important;
  font-weight: bold;
  /* color: #264ae4 !important; */
  color: #810055 !important;
}

.nav-list-item.inactive {
  background-color: #ffffff;
}

.nav-list-item .menu-icon {
  padding: 0.25rem;
  color: #810055;
}

.nav-sub-menu {
  list-style-type: none;
  padding-left: 3.5rem;
}

.sub-menu-item {
  text-decoration: none;
  color: #707070;
  font-size: 14px;
}

.nav-item:hover .sub-menu-item {
  color: #810055 !important;
}

.nav-sub-menu .nav-item {
  /* margin-top: 0.5rem; */
}

.side-bar {
  width: 20%;
  min-height: 100vh;
  flex: 0 0 auto;
  background-color: #ffffff;
  transition: all 0.4s ease;
  /* box-shadow: 0px 1px 3px 3px #f2f2f2; */
  border: 1px solid #F1F1ED;
}

/* @media (max-width: 768px) {
  .head-logo {
    display: none;
  }
} */
@media (max-width: 768px) {
  .side-bar {
    width: 90%;
  }

  /* .side-bar.open .sidebar-godrej-logo {
    width: 80px;
    margin-left: 10rem;
    margin-top: 25rem;
  } */

  .user-section {
    display: flex;
  }

  .sidebar-head {
    display: none
  }

  .side-bar.closed {
    display: none;
  }

  .side-bar.open {
    display: block;
    z-index: 10000;
    position: fixed !important;
  }
}