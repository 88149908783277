.grid-comp-containerCss {
  background-color: #fff;
}

.agCellCss {
  display: block;
}

.agAttachmentCellCss {
  display: inline-flex;
}

.checklistIndicator {
  font-size: 12px;
  font-family: "G&B-BodyCopy";
  padding: 0.25rem;
  padding-block: inherit;
  text-align: center;
  border-radius: 3px;
  height: 32px;
  width: 134px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

.swal-notealert {
  .swal2-actions {
    border-top: none !important;
  }

  font-size: 14px;
  font-family: "G&B-BodyCopy";
}

.action-checklist-container {
  padding: 1%;
}

.checklistNoteInfo {
  background-color: #FFFFFF;
  width: 100%;
  /* height: 12%; */
  padding-left: 1%;
}

/* .checklistform-select {
  border-radius: 1%;
  border: 1px solid #D8D8D8;
  font-family: "G&B-BodyCopy";
  font-size: 14px;
}

.checklistform-select:focus {
  border-color: #D8D8D8;
  box-shadow: none;
} */

.chklstMainContainer {
  /* background-color: #FFFFFF !important; */
  padding: 1% !important;
}

.checklistVersion-container {
  border: 2px solid #F1F1ED;
  /* background-color: #F5F5F6; */
  border-radius: 5px !important;
}

/* .checklistFormControl {
  color: #767980;
  background-color: #F5F5F6;
  border: none;
} */

.hideDiv {
  display: none;
}

.ImageGodrej {
  height: 34px;
  width: 70px;
}

.deleteIconSpan {
  width: 160px !important;
  overflow: inherit;
}

.contectFooterCss {
  background-color: #FFFFFF;
  border-radius: 5px !important;
}

.marginLeftCss {
  padding: 1%;
}

.tempSaveResp {
  width: 105px !important;
}

/* For Responsive screen START ******************************************************************/
@media screen and (max-width: 768px) {

  .tempSaveResp {
    width: 90px;
    height: 32px;
  }

  .icon-border {
    float: none;
  }

  .checklistIndicator {
    padding-block: inherit;
    border-radius: 3px;
    height: 32px;
    width: 160px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checklistNoteInfo {
    padding-left: 5% !important;
  }

  .action-checklist-container {
    padding: 2%;
  }

}

/* END ******************************************************************************************/

.marginBottomCss {
  margin-bottom: 1%;
}

.alertFont {
  color: #810055;
  font-size: 12px;
  font-family: "G&B-Headline" !important;
}

.font12Css {
  font-size: 12px;
  font-family: "G&B-BodyCopy" !important;
}

.noteCss {
  font-size: 14px;
  color: #333333;
  font-family: "G&B-Headline" !important;
}

.modalPopUpIconProjchklst {
  color: #707070;
  margin-top: -2rem;
  margin-right: 0.7rem;
  float: right;
  z-index: 1000;
  position: relative;
}

.buttonsCss {
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 5%;
}

.statusImg {
  width: 16px;
  height: 17px;
}

.ddinputselect {
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  /* padding-left: 1px; */
}

/* .checklist-option-cards {
  border: 1px;
  font-size: 14px;
  font-family: "G&B-Headline" !important;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #E6EDFE;
  color: #0636A5;
} */

/* .checklist-cards-header {
  height: 30px
} */

.agCellChklstCat {
  display: flex;
  /* margin-top: 4px; */
  padding-left: 8px;
  padding-right: 8px;
}

.rowHeader {
  padding-right: 10px;
}

.rowFooter {
  padding-top: 10px;
}

/* .backArrowNote {
  color: #0636A5;
  font-size: 14px;
  font-family: "G&B-BodyCopy";
} */

/* .viewDownloadProjChklst {
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  color: #0636A5;
  float: left;
} */

/* .backArrowbtn {
  float: right;
} */

.versionCodeCss {
  display: flex;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none;
}