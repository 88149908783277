.bread-crumb {
  height: 24px;
  padding: 2px 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-top: 1px solid #F1F1ED;
  border-bottom: 1px solid #F1F1ED;
  font-size: 12px;
}

.breadcrumb-item {
  padding-left: 0px !important;
}

.breadcrumb-item a {
  text-decoration: none !important;
  color: #707070;
}

.breadcrumb-item+.breadcrumb-item::before {
  /* float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);*/
  color: var(--bs-breadcrumb-divider-color);
  content: url("../../../icons/breadcrumb_divider.svg");
  height: 24px;
  width: 24px;
  padding-top: 0px;
  margin-top: -2px;
}

.breadcrumb-item.active {
  color: #707070;
  font-family: "G&B-Headline";
}

.breadcrumb {
  margin: 0 !important;
  font-size: 12px;
  /* font-weight: 400; */
  height: 24px;
}

@media screen and (max-width: 768px) {
  .bread-crumb {
    display: flex;
    justify-content: center;
  }
}