ul.options {
  border: 1px solid;
  display: block;
  list-style: none;
  /* width: 30rem; */
  transition: width 0.3s;
  /*margin: auto;*/
  margin-block-start: 0.3em;
  padding-inline-start: 0px;
  position: absolute;
  width: 100%;
  z-index: 1000;

}

ul.options li {
  display: block;
  background: white;
  /*margin: 10px auto;*/
  /*padding: 10px;*/
  font-size: 12px;
  width: 100%;
  border-radius: 2px;
}

ul.options li:hover {
  font-weight: bold;
  color: #333333;
  cursor: pointer;
  transition: 0.3s all;
}

ul.options li.option-active {
  background: whitesmoke;
  font-size: 16px;
  color: #333333;
}

.no-options {
  color: white;
}