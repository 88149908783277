.column-heading {
    left: 20px;
    position: relative;
    text-align: center;
    height: 100px;
}

.menu-header {
    display: flex;
}

.text-size {
    font-size: 14px;
    padding: 5px;
    align-content: center;
    border: 1px solid #F1F1ED;
    text-align: center;
    color: #333333
}

.menuheadCol {
    text-align: left;
}

.tabledataformat {
    padding: 10px;
}

.parentMenuRow {
    background-color: #F1F1ED;
    font-size: 14px;
    padding: 5px;
    border: 1px solid #F1F1ED;
    align-content: center;
    text-align: center;
    color: #333333;
    font-family: "G&B-Headline", sans-serif;
}

.subMenuRow {
    font-size: 14px;
    padding: 5px;
    border: 1px;
    border: 1px solid #F1F1ED;
    align-content: center;
    text-align: center;
    color: #333333;
}

.remark {
    /* border: 1px solid gainsboro;  */
    height: 35px;
    align-content: center;
    background-color: #F1F1ED;
    color: #707070;
    font-size: 14px;
}

/* .remarkalert {
    border: 1px solid gainsboro;
    height: 35px;
    align-content: center;
    background-color: #FAE8E6;
    color: #D11800;
    font-size: 14px;
} */

.parentMenuRowMob {
    background-color: #F1F1ED;
    font-size: 14px;
    padding-top: 4px;
    border: 1px solid #F1F1ED;
    color: #333333;
    /* font-weight: bold; */
    height: 30px;
    font-family: "G&B-Headline", sans-serif;
    padding-bottom: 10px;
}

.parentCheckBoxRowMob {
    font-size: 14px;
    color: #333333;
    background-color: #F1F1ED;
}

.selectall {
    font-style: italic;
    font-size: 12px;
    font-weight: normal;
}

.mob-selectall {
    color: #707070;
    font-style: italic;
    /* padding-left: 102px; */
    text-align: end;
}

.mob-selectall-check {
    padding-left: 0px;
}

.subMenuRowMob {
    font-size: 14px;
    color: #333333;
    padding: 10px 0px;
}

.ckeckbox-row-mob {
    font-size: 14px;
    color: #707070;
    border-bottom: 1px solid #F1F1ED;
}

.ckeckbox-row-mob .col-1,
.ckeckbox-row-mob .col-3 {
    padding-bottom: 10px;
}