.mandatoryToggleLabel {
  color: #333333;
}

.nonMandatoryToggleLabel {
  color: #707070;
}

.lblmandatory {
  font-family: "G&B-BodyCopy" !important;
}

.checklist-header-column .row {
  padding: 0 !important;
}

.parameterNote {
  border: 2px solid #f1f1f1;
  padding: 0.5rem;
}

.lockSignCss {
  margin-left: -5px;
}

/* css for PDF */
.pdfDiv {
  /* padding: 5px; */
  border: 1px solid #000000;
  font-size: 14px;
  font-family: "G&B-BodyCopy", sans-serif;
  color: #333333;
}

.pdfDiv .row {
  margin: 0 !important;
}

.pdf-css {
  margin: 10px;
  /* border: 1px solid black; */
}

.after {
  page-break-after: always;
}

.avoid-break {
  page-break-after: avoid;
}

.pdfTable {
  border-color: unset;
  border-collapse: collapse;
  border-style: hidden;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  width: 100%;
  border-spacing: 0 !important;
  font-weight: 100 !important;
}

.pdfTable .cell {
  border-collapse: collapse;
  border: 1px solid #000000;
  vertical-align: top;
}


.pdfTable th {
  font-family: "G&B-Headline", sans-serif;
  font-weight: 100 !important;
}

.footerTD {
  padding: 0 !important;
  border: none !important;
}

.footerRow {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-bottom: 0 !important;
}

.footerBootomBorder {
  border-bottom: 1px solid #000000;
}

.footerRightBorder {
  border-right: 1px solid #000000;
}

.noBorder {
  border: none;
}

.printButton {
  margin-left: 82%;
}

@media screen and (max-width: 768px) {
  .printButton {
    margin-left: 50%;
  }
}

@media (max-width: 768px) {
  .pdfTable .cell {
    word-break: break-word;
  }
}

.main-pdf-sizing {
  width: 205mm;
  margin-top: 5mm;
  margin-bottom: 5mm;
  page-break-after: always;
}

.pdf-height {
  height: 280mm;
}

.header1style {
  text-align: center;
  line-height: 59px;
}

.top-margin-8 {
  margin-top: 8px;
}

.border-hidden {
  border: hidden;
}

.bottom-border {
  border-bottom: 1px solid black;
}

.position-relative {
  position: relative;
}