@font-face {
  font-family: "G&B-BodyCopy";
  src: url("../../fonts/G&B-BodyCopy.woff2") format("woff2"),
    /* url("../../fonts/G&B-BodyCopy.woff") format("woff"), */
    url("../../fonts/G&B-BodyCopy.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.filter-col {
  font-size: 14px;
  max-width: max-content;
  background-color: #F9F2F6;
  border-radius: 16px;
  display: flex;
  place-items: center;
  justify-content: space-around;
  padding: 0px 10px;
}

.actionButtons :hover {
  color: #810055 !important;
}

/* .ag-center-cols-clipper {
  min-height: 200px !important;
} */

.ag-theme-alpine .ag-row-odd {
  background-color: white;
}

.ag-theme-alpine .ag-row-even {
  background-color: white;
}

/* .ag-theme-alpine.ag-row-odd.ag-row-even:hover {
  --ag-background-color: white;
  --ag-row-hover-color: #F9F2F6;
}

.ag-theme-alpine.ag-row:hover {
  border: 1px solid rebeccapurple;
} */

.ag-theme-alpine {
  font-family: "G&B-BodyCopy";
  /* --ag-background-color: white; */
  --ag-row-hover-color: #F9F2F6;
  /* --ag-column-hover-color: rgba(249, 242, 246, 1); */
  --ag-selected-row-background-color: #F9F2F6 !important;
  --ag-header-cell-hover-background-color: #707070;
}

/* .ag-row-selected:active:hover {
  background-color: #f89fd2 !important;
} */

/* .ag-row-selected::before {
  background-color: #F9F2F6 !important;
}

.ag-row-selected::before :hover {
  background-color: #F9F2F6 !important;
} */

/* .ag-theme-alpine,
.ag-theme-alpine-dark {
  --ag-selected-row-background-color: #F9F2F6 !important;
} */

.cardContainer {
  margin-bottom: 0.5rem;
  border: white;
  border-radius: 5px;
  border: 1px solid #D8D8D8;
}

.colHeader {
  flex: 1 0;
  width: 40%;
  color: #333333;
  margin-left: 0.5rem;
  text-align: left;
  font-size: 12px;
  font-family: "G&B-BodyCopy";
  margin-bottom: 0.5rem;
  padding-right: 0px;
}

.colData {
  flex: 1 0;
  width: 60%;
  padding-left: 0px;
  color: #333333;
  text-align: left;
  font-size: 12px;
  font-family: "G&B-BodyCopy";
  margin-bottom: 0.5rem;
}

.colChklstStatus {
  display: inline-flex;
}

.cardbodycontainer {
  padding: 0px 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cardHeaderResponsive {
  /* font-weight: 600; */
  color: #333333;
  font-size: 12px;
  font-family: "G&B-Headline", sans-serif;
  letter-spacing: 0px;
  opacity: 1;
}

.cardbodycontainerResponsive {
  padding: 1rem;
  /* background-color: #F1F1ED; */
}

.ag-header-cell {
  background-color: #707070 !important;
}

/* .ag-header-cell:hover {
  background-color: #aaaaaa !important;
} */

.ag-header-icon {
  color: #ffffff !important;
}

.ag-icon-asc,
.ag-icon-desc::before {
  color: #ffffff !important;
}

.ag-header-cell-text {
  text-align: left;
  font-family: "G&B-Headline";
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
  font-weight: 100 !important;
}

.ag-cell-value {
  text-align: left;
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  align-content: center;
  display: grid;
}

/* .ag-cell-valueIstEquipment {
  text-align: left;
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  align-content: center;
  display: block
} */

/* select.form-select1 {
  color: #fff;
  background-color: #7f86a0;
  margin: auto;
} */

.ag-filter-apply-panel {
  font-family: "G&B-BodyCopy";
  font-size: 14px;
}

.ag-filter-apply-panel button[type=submit] {
  color: #810055 !important;
}

.ag-filter-apply-panel button[type=button] {
  color: #333333 !important;
}

.ag-theme-alpine .ag-standard-button,
.ag-theme-alpine .ag-standard-button :hover {
  background-color: white !important;
  border: none !important;
}

.ag-select .ag-picker-field-display {
  border-bottom-style: solid !important;
  border-width: 1px;
  font-family: "G&B-BodyCopy";
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 14px;
  text-align: left;
}

/* .ag-theme-alpine {
  --ag-borders-input: solid 1px; 
  --ag-input-border-color: #0636A5;
} */

/* .ag-theme-alpine .ag-ltr input {
  border-radius: 0px !important;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  border-color: #0636A5 !important;
  border-bottom-style: solid;
  border-bottom-width: revert;
} */

.grid-master-container {
  height: calc(100vh - 5rem);
  padding: 0;
  background-color: #FFFFFF;
}


.grid-comp-container {
  padding: 1% 1% 2% 1%;
  background-color: #FFFFFF;
}


/* .ag-theme-material .ag-filter-apply-panel {
    padding: 0px 12px 12px !important;
  } */

/* .ag-theme-material .ag-simple-filter-body-wrapper>* {
  margin-bottom: 8px !important;
  --ag-row-hover-color: #F9F2F6 !important;
  --ag-column-hover-color: #F9F2F6 !important;
} */

/* .ag-theme-material .ag-ltr .ag-filter-apply-panel-button {
    margin-left:  auto !important;
  } */

.ag-theme-alpine {
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size)) !important;
  --ag-checkbox-checked-color: #810055 !important;
  --ag-input-focus-border-color: none !important;
}

/* .ag-theme-alpine .ag-standard-button {
    color: var(--ag-alpine-active-color) #810055 !important;
    border-radius: var(--ag-border-radius);
    border: none !important;
    border-color: none !important;
    padding: none !important;
  } */

.ag-picker-field-wrapper {
  border: 0px solid;
  border-color: var(--ag-border-color);
  border-radius: 5px;
}

.ag-theme-alpine .ag-menu {
  background-color: #FFFFFF !important;
}

.ag-input-field-input ag-text-field-input {
  border: none !important;
}


.popup {
  --ag-borders: none;
}

.ag-sort-order {
  display: none !important
}

.ag-pinned-right-header,
.ag-cell.ag-cell-first-right-pinned {
  border-left: none !important;
}

.agGridContainer {
  height: 400px;
  width: 100%;
}

.ag-root-wrapper {
  border: 1px solid #F1F1ED;
}

.ag-row {
  --ag-row-border-color: #F1F1ED;
}

/* .ag-row:hover {
  background-color: #F9F2F6;
  color: #F9F2F6;
} */

.ag-header {
  background-color: #fff;
}

.ag-paging-panel {
  border-top-color: #F1F1ED;
  color: #333333;
}

.ag-paging-button {
  color: #333333;
}

.ag-paging-button.ag-disabled {
  color: #707070;
}

.clear-all-btn {
  font-size: 12px;
  font-family: 'G&B-Headline';
  width: 86px !important;
  height: 32px !important;
  border-radius: 20px;
}

.ag-cell:focus {
  border: none !important;
  outline: none;
}

.agGridContainerEquip {
  height: 300px;
  width: 100%;
}

.agGridLeftContainerEquip {
  height: 220px;
  width: 100%;
}

.table-container1 {
  overflow: auto;
}