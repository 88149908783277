/* .segmented-progresscircle {
    position: relative;
    display: inline-block;
} */

.segmented-progresscircle svg {
    width: 85px;
    height: 85px;
}

.segmented-progresscircle circle {
    fill: transparent;
}

.segmented-progresscircle__circles {
    transform: rotate(-90deg);
}

.segmented-progresscircle__circles__progress-dashes {
    stroke-linecap: round;
    stroke: #810055;
}

.segmented-progresscircle__circles__background-dashes {
    stroke-linecap: round;
    stroke: #707070;
    stroke-dasharray: 6;
}

.segmented-progresscircle__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 7000;
    text-align: center;
    font-family: 'G&B-Headline, sans-serif';
}

.segmented-progresscircle__text__primary {
    font-size: 12px;
    color: #333333;
}

.segmented-progresscircle__text__secondary {
    font-size: 12px;
    color: #707070;
    margin-bottom: 2px;
}

.msg-color {
    color: #333333;
}

.processing-color {
    color: #3CB23D;
}