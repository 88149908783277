
.app-container {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
  width: 100%;
  
}
.app-layout {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  max-width: 100vw !important;
  display: flex;
  flex-direction: row; 
}
