.header {
  height: 3.5rem;
  color: #333333;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid #e0e0e0; */
}

.responsive-header {
  display: none;
}

.header-menu-name {
  padding: 0.5rem;
  font-family: "G&B-Headline";
  font-size: 18px;
  color: #810055;
  opacity: 1;
  /* font-weight: 600; */
}

.header-user-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-user-info {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
  justify-content: center;
  align-items: flex-start;
  color: #333333;
  padding: 0.25rem;
  font-size: 12px;
}

.header-user-name {
  font-size: 14px;
  font-family: "G&B-Headline";
  letter-spacing: 0px;
  color: #333333;
}

.header-user-email {
  font-size: 10px;
  font-family: "G&B-Headline";
  letter-spacing: 0px;
  color: #333333;
}

.header-user-logo {
  height: 2.5rem;
  width: 2.5rem;
  /* background-color: #a4a5aa; */
  background-color: #F9F2F6;
  /* color: #ffffff; */
  color: #810055;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .responsive-header {
    display: flex;
    width: 100%;
    align-items: center;
    /* box-shadow: 0px 3px 6px #F2F2F2; */
    border-bottom: 1px solid #F1F1ED;
    padding-bottom: 10px;
  }

  .responsive-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-right: 50px;
    margin-left: 5%;
  }

  .header-logo {
    font-size: 26px;
    font-weight: bolder;
    font-family: "G&B-Headline";
    color: #810055;
  }

  .header-system-name {
    font-size: 12px;
    font-weight: bold;
    font-family: "G&B-BodyCopy";
    color: #707070;
  }

  .header-menu-name {
    /* padding: 0.5rem 0; */
    font-size: 12px;
    /* font-weight: 600; */
  }

  .header-user-section {
    display: none;
  }
}


.menu-name-header {
  font-weight: 600;
  font-size: 18px;
  margin: 0.5rem;
}

.menu-name {
  width: 100%;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}



.img2 {
  position: fixed;
  margin-left: -32px;
}

.masterImg {
  position: fixed;
  margin-left: -32px;
  margin-top: 4px;
  padding-left: 4px;
}

.adminImg {
  position: fixed;
  margin-left: -27px;
  margin-top: 1px;
  padding-left: 3px;
}

.img1 {
  position: relative;
}

.nav-list-item:hover .sidebar-icon,
.nav-list-item.active .sidebar-icon {
  filter: brightness(0) saturate(100%) invert(7%) sepia(98%) saturate(5306%) hue-rotate(314deg) brightness(89%) contrast(103%);
}

.list1:hover .img2 {
  opacity: 0;
}

.list2:hover .img2 {
  opacity: 0;
}

.list3:hover .img2 {
  opacity: 0;
}

.list4:hover .img2 {
  opacity: 0;
}

.list5:hover .img2 {
  opacity: 0;
}

.list6:hover .img2 {
  opacity: 0;
}

.list6:hover .masterImg {
  opacity: 0;
}

.list5:hover .adminImg {
  opacity: 0;
}