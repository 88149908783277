/*@import "./components/table";
@import "./components/timeline";
@import "./components/customPages";
@import "./layout/color";
@import "./layout/resmixins";
@import "login";
@import "login";


@import "../layout/color";*/

body {
  margin: 0;
  padding: 0;
}

.login-container {
  overflow: hidden;
}

.logoHeader {
  padding: 0rem 6rem;
  border: 1px solid #F1F1ED;
  position: absolute;
  align-items: center;
  justify-content: space-between !important;
  background-color: white;
  height: 56px;
  margin: 0 !important;
  width: 100%;
}

.login-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  color: #810055;
}

.login-logo-head {
  font-size: 3.75rem;
  font-family: 'G&B-Headline', sans-serif;
}

.login-logo-title {
  font-size: 1.25rem;
  font-family: 'G&B-Headline', sans-serif;
}

.headerLink {
  font-size: 0.75rem;
  color: #810055;
}

.LoginRightRadioDivTitle {
  font-size: 12px;
}

.LoginRightRadioDiv {
  display: flex;
  gap: 20px;
}

.LoginRightRadioLabel {
  font-family: "G&B-BodyCopy", sans-serif;
  font-size: 16px;
}

.forgotButton {
  width: 100%;
  border-radius: 50rem;
  font-family: "G&B-BodyCopyHighlight", sans-serif;
  font-size: 16px;
  padding: 16px;
}

.loginBg {
  background: url(../icons/GCEM.jpg) no-repeat;
  background-position: 0 0 !important;
  background-size: cover;
  height: 100vh;
}

.LoginForgotTitle {
  font-size: 16px;
  font-family: "G&B-Headline", sans-serif;
  margin-bottom: 20px
}

.loginImgRight1 {
  width: 15%;
}

.loginImgRight2 {
  width: 38%;
}

.forgotText {
  cursor: pointer;
  color: #767980;
  font-size: 14px;
  position: absolute;
}

.forgotModalText {
  cursor: pointer;
  right: 0;
  position: absolute;
  padding-right: 3rem;
}

.loginModal {
  justify-content: flex-start;
}

.loginErrorDiv {
  background: #FEF1F1;
  color: #E1142E;
  border-radius: 5px;
  text-align: left;
  padding: 0.8rem;
  font-size: 14px;

  .loginErrorDivHeader {
    font-family: "G&B-Headline", sans-serif;
  }
}

/*
.forgotPasswordModal {
  .modal-body {
    text-align: center;

    .modalHeading {
      font-weight: 600;
      margin: 20px 0px 10px 0px;
    }

    .modalText {
      font-size: 14px;
      padding: 0 5rem 0 5rem;
      color: $aemGrey;
    }
  }
}
*/
.alertBoxLogin {
  position: fixed;
  z-index: 100;
  width: -webkit-fill-available;
  margin: 15px 15px 0px 15px;
}

.textright {
  text-align: right;
  color: $aemGrey;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

/*
@media (max-width: 767px) {
  .forgotText {
    cursor: pointer;
    color: #767980;
    font-size: 14px;
    position: absolute;
  }

}
*/
.place-right {
  display: flex !important;
  align-items: center;
  justify-content: space-evenly;
}

/*
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
*/

.form-control:disabled {
  background-color: white;
  opacity: 1;
}

.loginForm {
  padding: 8rem 3rem;
  color: #707070
}

.loginForm h1 {
  font-size: 3.75rem;
  font-family: 'G&B-Headline', sans-serif;
}

.loginForm h5 {
  font-size: 1.25rem;
  font-family: 'G&B-Headline', sans-serif;
  color: #707070
}

.text-start {
  text-align: left !important;
}