/* Pagination CSS */

.pagintion-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1%;
  height: 2rem;
  font-size: 12px;
  color: #707070;
  /* background-color: #F1F1ED; */
  padding: 1%;
}

.pagintion-bar svg {
  cursor: pointer;
  color: #333333;
  margin: 2px 3px;
}

.pagintion-bar .disabled {
  pointer-events: none;
  color: #707070;
}

.pagintion-bar ul {
  padding-top: 1rem;
  color: #333333;
}

/* Pagination Ends */