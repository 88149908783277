.StatusIndicator {
  font-size: 12px;
  font-family: "G&B-BodyCopyHighlight";
  padding: 0.25rem;
  padding-block: inherit;
  text-align: center;
  border-radius: 3px;
  height: 24px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeStatus {
  color: #3CB23D;
  background-color: rgba(51, 192, 55, .1);
}

.inactiveStatus {
  color: #707070;
  background-color: rgba(112, 112, 112, .1);
}

.rejectedStatus {
  color: #E1142E;
  background-color: rgba(225, 20, 46, .1);
}