.modal-xl {
  --bs-modal-width: 923px;
  --bs-modal-height: 1090px;
}

.ag-header-cell-text {
  text-align: left;
  font-family: "G&B-Headline";
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

/* .ag-theme-material {
  --ag-row-hover-color: #F9F2F6;
  --ag-column-hover-color: #F9F2F6;
} */

.g-input label {
  font-family: "G&B-BodyCopy";
  font-weight: normal;
  font-size: 14px;

}

.column-heading {
  left: 20px;
  position: relative;
  text-align: center;
  height: 100px;
}

.menu-header {
  display: flex;
}

/* .text-size {
  font-size: 14px;
  padding: 5px;
  align-content: center;
  border: 1px solid gainsboro;
  text-align: center;
} */

.nav-tabs {
  border-bottom: 2px solid #EBEBEC;
}

.nav {
  --bs-nav-link-padding-x: none;
}

.add-container {
  /* padding-left: 16px; */
  /* margin: 0 5px; */
  /* border-top: 2px solid #F1F1ED !important; */
}

.add-container .nav-item {
  width: auto;
  flex: 1 1 auto;
  text-align: center;
  border-right: #EBEBEC 1px solid;
  padding: 0 0 0.5rem 1rem;
}

.nav-fill .nav-item .nav-link,
.nav-tabs .nav-link {
  color: #707070;
  border: none;
  border-color: transparent;
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  border-radius: 0px;
}

.nav-tabs .nav-link.active {
  color: #810055;
  /* border-color: transparent; */
  /* --bs-nav-tabs-link-active-border-color: #810055; */
  /* border-bottom: 2px solid; */
  font-family: "G&B-Headline", sans-serif;
  font-size: 14px;
  /* border-right-color: #EBEBEC;
  border-right-style: 2px solid; */
  border-radius: 0px;
}

.nav-item:has(.nav-link.active) {
  border-bottom: 2px solid #810055;
}

.nav-tabs .nav-link.active>.numberbg {
  border: 1px solid #810055;
  color: #810055;
}

.nav-link>.numberbg {
  background-color: white;
  border: 1px solid #707070;
  color: #707070;
  width: 21px;
  height: 21px;
  margin: 0 0.5rem 0 0;
  display: inline-block;
  border-radius: 21px;
}

.headerTab {
  padding-left: 0px;
  padding-right: 0px;
}

.labelText {
  color: #810055;
  font-size: 12px;
}

/* .hstack {
   display: block;
} */

.nxtbtnText {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.prevHideButton {
  display: none;
}

/* .btn-outline-primary {
  width: 109px;
} */

.prevButton {
  margin-left: 60%;
  font-family: "G&B-BodyCopy";
  font-size: 14px;
}

.viewDownload {
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  color: #810055;
  display: inline-block;
  margin-left: 1rem;
}

.addNewRow {
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  color: #810055;
  display: flex;
  justify-content: end;
}

/* input[type="date"]:focus,
input[type="date"]:valid {
  color: #666666;
} */

.SubHeaderProjectDetails {
  color: #333333;
  font-family: "G&B-Headline";
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 1%;
  margin-bottom: 2%;
}

.stakeDeleteIcon {
  float: right;
  height: 32px;
  width: 32px;
}

.stakeDeleteIcon:hover {
  filter: brightness(0) saturate(100%) invert(7%) sepia(98%) saturate(5306%) hue-rotate(314deg) brightness(89%) contrast(103%);
}

.backdropModal {
  position: absolute;
  top: -600px;
  left: -20px;
  width: 300vw;
  height: 700vh;
  background: rgba(0, 0, 0, 0.55);
}

.titleModelResponsive {
  font-size: 16px;
  padding: 1px 1px 1px;
  line-height: 0;
}

.cardContainerResponsive {
  padding: 0px;
  margin: 4px 2px 2px 2px;
}

.cardContainer {
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: 1px solid #D8D8D8;
}

.btnforResponsive {
  float: right;
  width: 109px;
  height: 40px;
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  color: white;
  background-color: #810055;
}

.prevbtn {
  height: 40px;
  width: 109px;
  font-family: "G&B-BodyCopy";
  font-size: 14px;
}

.prevTabBtn {
  align-items: end;
}

/* For Responsive screen START ******************************************************************/
@media screen and (max-width: 768px) {
  .stakeholderArrowResponsive {
    margin-top: -22px;
    margin-right: 24px;
    float: right;
    width: 0%;
  }

  .customerArrowResponsive {
    /* margin-right: 26px; */
    display: block;
    /* justify-content: right; */
  }

  .stakeholderCssResponsive1 {
    margin-bottom: 8%;
    display: block;
    height: 19px;
    color: #810055;
    font-family: "G&B-BodyCopy";
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    padding-left: 0%;
  }

  .stakeholderCssResponsive {
    display: block;
    height: 19px;
    color: #810055;
    font-family: "G&B-BodyCopy";
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    padding-left: 0%;
    margin-top: 1%;
  }

  .nav-tabs {
    --bs-nav-tabs-link-active-bg: none;
    --bs-nav-tabs-link-active-border-color: none;
  }


  .btnforResponsive {
    width: 72px;
    height: 32px;
    font-family: "G&B-Headline" !important;
    font-size: 12px;
    color: white;
    background-color: #810055;
    margin-right: auto;
  }

  .prevbtn {
    float: right;
    width: 72px;
    height: 32px;
    font-family: "G&B-Headline" !important;
    font-size: 12px;
  }

  .showDisplay {
    display: block;
  }

  .hideDisplay {
    display: none;
  }

  .actionIcons {
    text-align: right;
    margin-bottom: 0.4rem;
    display: block;
  }

  .deleteStakeRowResponsive {
    font-family: "G&B-BodyCopy";
    font-size: 14px;
    color: #810055;
    display: flex;
    margin-left: 48%;
    padding-bottom: 2%;
  }

  .borderLine {
    display: block;
    border: 1px solid #6F6E6F;
    --bs-gutter-x: 3rem;
    padding-bottom: 0px;
  }

  .global-search-containerResponsive {
    display: block;
    margin: 0rem 0 1rem 0;
    width: 239px;
  }

  .nav-tabs .nav-link.active {
    font-size: 12px !important;
  }


}

/* For Responsive screen END ******************************************************************/

/* .cardBorder {
  border-top: #6F6F70 1px solid;
  padding-bottom: 1rem;
} */

input[type='file'] {
  display: none;
}

.global-search-container {
  margin: 0rem 0 1rem 0;
}

.labelFont {
  font-family: "G&B-BodyCopy";
  font-size: 14px;
  font-weight: bold;
  color: #707070;
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input .fontCss {
  font-family: "G&B-BodyCopy";
  font-size: 14px;
}

.closebtn_responsive {
  margin-top: -40px;
}

.form-check-input:checked[type=radio] {
  background-color: #810055;
  border-color: #810055;
}

.blueColor {
  color: #810055;
}

.projectName {
  width: "582px";
  margin-left: "2px";
}

.deletelinklabel {
  text-decoration: underline;
  width: "97px";
  padding-top: 5%;
  color: #810055
}

.labelfield {
  font-size: 14px !important;
  color: #333333;
  font-weight: normal;
  font-family: "G&B-BodyCopy !important"
}

.form-select {
  border-radius: 0px;
  border: 1px solid #fff;
}

.form-select:focus {
  border-color: #fff;
  box-shadow: none;
}

.plusIcon {
  color: #fff;
  margin-left: 0.1px;
  margin-bottom: 4px;
  border-radius: 50%;
  background-color: #810055;
  padding: 0.2rem
}

.span_plusIcon {
  border-radius: 50%;
  background-color: #810055;
  height: 18px;
  width: 16px;
}


.modalPopUpIconProjDetails {
  color: #707070;
  margin-top: -2rem;
  margin-right: 0.7rem;
  float: right;
  z-index: 1000;
  position: relative;
}

.modalPopUpIcon {
  color: #707070;
  float: right;
  margin-top: 0.5rem;
}

.ag-select .ag-picker-field-display {
  border-bottom-style: none !important;
}

.add-container .ag-select .ag-picker-field-display {
  padding-left: 1rem;
}

.dropZoneContainer1 {
  position: relative;
  background: #FFFFFF;
  border: 2px dashed #F1F1ED;
  border-radius: 5px;
  display: flex;
  height: 2.4rem;
  width: 14rem;
}

/* .dropOuterCircle {
  margin: 6px 8px 6px 8px;
} */

/* .dropTextContainer {
  margin-left: 0px;

  .dropText {
    display: inline-block;
  }

  .dropTextBlue {
    padding-left: 1px;
  }
} */

/* .dropOuterCircle {
  border: 1px solid #E2E2E2;
  width: 24px;
  height: 24px;
  margin: 6px 8px 6px 10px;
  border-radius: 50%;
  text-align: center;

  .dropInnerCircle {
    border: 1px solid #E2E2E2;
    background: #F8F8F8;
    width: 18px;
    height: 18px;
    margin: 2px;
    border-radius: 50%;
    text-align: center;
  }
} */

.imgInfowarning {
  margin-top: -1.8rem;
  float: right;
  padding-right: 0.5rem;
}

.infoMsg .tooltip-inner {
  /* background-color: #fff;
  color: #000000; */
  font-family: "G&B-BodyCopy", sans-serif;
  font-size: 14px;
  min-width: 30px;
  white-space: nowrap;
  min-width: max-content;
  /* border: 1px solid #000000; */
}

div.quote {
  /* display: none; */
  border: 1px solid #E5E5E5;
  height: 40px;
  width: 269px;
  box-shadow: 0px 3px 6px #00000029;
  background: var(--neutral-0) 0% 0% no-repeat padding-box;
}

img#info:hover+div.quote {
  display: block;
}

.viewExcelLink {
  color: #810055;
  text-decoration: none;
}

.tabCssheader {
  margin: 1rem 0;
  width: 100%;
  /* overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap; */
  display: inline-flex;
}

.modalSaveResponsive {
  /* background-color: #810055;
  border: 1px solid #810055;
  color: #fff;
  width: 72px;
  height: 32px;
  font-family: "G&B-Headline" !important;
  font-size: 12px; */
  float: right;
}

.tabDirectionbtnRow {
  margin-right: 1px;
  padding-right: 1px;
}

.grid-comp-containerCss {
  background-color: #fff;
}

.inlineEdit {
  /* display: block; */
  background-color: #F9F2F6 !important;
}

.blockCss {
  display: block;
  /* background-color: #cceaf8 !important; */
}

@media screen and (max-width: 768px) {

  .add-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .add-container .modal-body {
    padding: 0;
  }

  .tabCssheader {
    margin: 1rem 0;
    /* overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap; */
    display: inline-flex;
  }

  .add-container .nav-item {
    width: 20%;
    flex: 1 1 auto;
    text-align: center;
    padding: 0 0 0.5rem 0;
    display: flex;
    justify-content: center;
  }

  .nav-fill .nav-item .nav-link,
  .nav-tabs .nav-link {
    font-size: 12px;
  }

  /* .nav-tabs .nav-link.active {
    font-size: 12px;
  } */

  .nav-link>.numberbg {
    display: block;
    margin: auto;
  }

  .tabButtonPosition {
    width: 100% !important;
    box-shadow: 0px 3px 1px 1px #F1F1ED inset;
    padding-top: 5px;
  }

  .viewDownload {
    margin-left: 0 !important;
  }

  .dropZoneContainer1 {
    width: 100%;
  }
}


.tabButtonPosition {
  position: fixed;
  bottom: 0;
  width: 93%;
  /* left: 20%; */
  background-color: #fff;
  z-index: 1000;
}

.app-layout.openBar .tabButtonPosition {
  width: 78%;
  /* left: 27%; */
}

/* .app-layout.closeBar .tabButtonPosition {
  width: 85%; */
/* left: 5%; */
/* } */