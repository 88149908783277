.currency-master-container {
  /* background: #f5f5f6; */
  width: 100%;
  height: 100%;
}

.CurrencyFormDiv {
  /* padding: 4% !important; */
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  margin-bottom: 0.5rem !important;
}

.CurrencyForm {

  background: var(--neutral-0) 0% 0% no-repeat padding-box;
  border: 1px solid var(--neutral-40);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DEDFE0;
  opacity: 1;

}

/* .currency-master-container {
  height: calc(100vh - 5rem);

  background-color: #f5f5f6;
} */

.txt_text {
  text-align: left;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;



}

.txt_number {

  text-align: right;
  font-family: "G&B-BodyCopy", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  border: 1px solid #F1F1ED;

  /* background: var(--neutral-0) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #0038B3;
opacity: 1; */
}


#btnReset:hover {
  text-decoration-color: #810055;
  color: #810055;

}

.frmButtons {
  text-align: center;
  font-family: "G&B-BodyCopy", sans-serif;
  letter-spacing: 0px;
  font-size: 14px;
  /* color: #810055 !important; */
  opacity: 1;
  /* top: 331px;
left: 351px; */
  /* width: 109px;
height: 40px; */

}

/* .frm-label{
  top: 180px;
left: 361px;
width: 81px;
height: 14px;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/14px var(--unnamed-font-family-work-sans);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--neutral-60);
text-align: left;
font: normal normal normal 12px/14px Work Sans;
letter-spacing: 0px;
color: #B0B2B6;
opacity: 1;
} */